body {
  height: 100vh;
  /* height: 100%; */
  /* width:100%; */
  /* overflow: hidden !important; */
  /* aspect-ratio: 2; */
}
/* body, html{
  scroll-behavior: smooth !important;
} */

#div-canvas {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* aspect-ratio: 2; */
  z-index: 1000 !important;
}
#scrollProgress {
  background: green;
  display: inline-block;
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 99;
  font-size: 3vh;
}
.div-height {
  height: 400vh;
  width: 100%;
  background-color: gray;
  z-index: 1;
  position: relative;
  top: 0;
  left: 0;
}
#div-tamanio{
  width:100%;
  height:100%;
  /* background:blue; */
  position:fixed;
  z-index:1;
  left:0;
  top:0
}
.scroll-content {
  overflow-y: hidden !important;
}
.scrollbar-track.scrollbar-track-y {
  opacity: 1;
  background-color: #f1f1f1;
  width: 16px;
}
.scrollbar-thumb.scrollbar-thumb-y {
  border-radius: 0px;
  background-color: rgb(193, 193, 192);
  width: 12px;
  left: 2px;
}
